import { Component, OnInit } from '@angular/core';
import { DataStorageService } from '../shared/data-storage.service';
import { OpportunitiesService } from './opportunities.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-opportunities',
  templateUrl: './opportunities.component.html',
  styleUrls: ['./opportunities.component.css']
})
export class OpportunitiesComponent implements OnInit {

  constructor(
    private dataStoreService: DataStorageService,
    private opportunitiesService: OpportunitiesService,
    private router: Router
  ) { }

  ngOnInit() {
    console.log('opportunities component - ngOnInit');
    this.dataStoreService.fetchOpportunities();
  }

}
