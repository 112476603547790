import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ResearchModel } from '../research.model';
import { ResearchService } from '../research.service';
import { DataStorageService } from 'src/app/shared/data-storage.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ConfirmationModalService } from 'src/app/confirmation-modal/confirmation-modal.service';

declare var $: any;

@Component({
  selector: 'app-research-edit',
  templateUrl: './research-edit.component.html',
  styleUrls: ['./research-edit.component.css']
})
export class ResearchEditComponent implements OnInit {

  researchForm: FormGroup;
  public Editor = ClassicEditor;

  EditorConfig = {
    toolbar: ['Heading', 'Bold', 'Italic', 'Link', 'BulletedList', 'NumberedList', 'Undo', 'Redo']
  };

  constructor(
    private fb: FormBuilder,
    private researchService: ResearchService,
    private dataStorageService: DataStorageService,
    private route: ActivatedRoute,
    private router: Router,
    private confirmationModalService: ConfirmationModalService) { }

  ngOnInit() {
    console.log('onInit of Research-Edit Component');
    this.initForm();
  }

  onSubmit() {
    console.log('submitting research');
    // throw error if the title or description inputs are empty
    if (this.researchForm.value.title.length < 1) {
      this.confirmationModalService.displayConfirmationModal('title cannot be empty', 'failure', 2000);
    } else if (this.researchForm.value.description.length < 1) {
      this.confirmationModalService.displayConfirmationModal('description cannot be empty', 'failure', 2000);

    } else { // show success messaged when changed are successfully saved
      this.researchService.setResearchObject(this.researchForm.value);
      this.dataStorageService.storeResearch().subscribe(res => {
        this.confirmationModalService.displayConfirmationModal('changes saved successfully', 'success', 2000);
        this.onCancel();
      }, err => {
        console.log('error: ');
        console.log(err);
        this.confirmationModalService.displayConfirmationModal('something unexpected happened', 'failure', 2000);
      });
    }
  }

  initForm() {
    this.researchForm = this.fb.group({
      title: '',
      description: ''
    });
    this.dataStorageService.fetchResearch(); // necessary in case user goes directly to research/edit without going to /research first
    this.onResearchChanged();
  }

  // re-renders as research data in the service changes
  onResearchChanged() {
    this.researchService.researchChanged.subscribe(
      (researchObj: ResearchModel) => {
        this.researchForm = this.fb.group({
          title: researchObj.title,
          description: researchObj.description
        });
      }
    );
  }

  onCancel() {
    console.log('cancelling/after submitting');
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
