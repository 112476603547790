import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';

import { NewsService } from '../news/news.service';
import { NewsModel } from '../news/news.model';
import { AuthService } from '../auth/auth.service';
import { PeopleModel } from '../people/people.model';
import { PeopleService } from '../people/people.service';
import { OpportunitiesModel } from '../opportunities/opportunities.model';
import { OpportunitiesService } from '../opportunities/opportunities.service';
import { ResearchService } from '../research/research.service';
import { PublicationsService } from '../publications/publications.service';
import { PublicationsModel } from '../publications/publications.model';

@Injectable()
export class DataStorageService {

  private url: string;

  constructor(
    private http: Http,
    private newsService: NewsService,
    private authService: AuthService,
    private peopleService: PeopleService,
    private opportunitiesService: OpportunitiesService,
    private researchService: ResearchService,
    private publicationsService: PublicationsService
  ) {
    this.url = 'https://bclab-news-34abb.firebaseio.com';
  }



  storePeople() {
    const token = this.authService.getToken(); // try to do this in constructor only once and see if it still works properly
    const peopleArray = this.peopleService.getPeople();

    return this.http.put(this.url + '/people.json?auth=' + token, peopleArray);
  }

  fetchPeople() {
    this.http.get(this.url + '/people.json')
      .subscribe(
        (res) => {
          const peopleArray: PeopleModel[] = res.json();
          this.peopleService.setPeople(peopleArray ? peopleArray : []);
        }, error => {
          console.log(error);
        }
      );
  }

  storeNews() {
    const token = this.authService.getToken();

    return this.http.put(this.url + '/news.json?auth=' + token, this.newsService.getNews());
  }

  fetchNews() {
    return this.http.get(this.url + '/news.json');
  }

  // Research
  fetchResearch() {
    console.log('FETCHING RESEARCH');
    this.http.get(this.url + '/research.json')
      .subscribe(
        (res) => {
          const researchObj = res.json();
          this.researchService.setResearchObject(researchObj)
        }
      );
  }

  storeResearch() {
    console.log('store publication');
    const token = this.authService.getToken();
    const researchObj = this.researchService.getResearchObject();

    return this.http.put(this.url + '/research.json?auth=' + token, researchObj);
  }

  // Publications
  fetchPublications() {
    console.log('FETCHING PUBLICATIONS');
    return this.http.get(this.url + '/publications.json');
  }

  storePublications() {
    console.log('putting publications in database');
    const token = this.authService.getToken();
    // this below causes an error because when I post something to backend and it fails,
    const publications = this.publicationsService.getPublications();
    return this.http.put(this.url + '/publications.json?auth=' + token, publications);
  }

  // Opportunities
  fetchOpportunities() {
    console.log('fetching opportunities');
    return this.http.get(this.url + '/opportunities.json');
  }

  storeOpportunities() {
    console.log('data-storage.service - storeOpportunities()');
    const token = this.authService.getToken();
    const opportunities = this.opportunitiesService.getOpportunities();
    return this.http.put(this.url + '/opportunities.json?auth=' + token, opportunities);
  }
}
