import { Component, OnInit } from '@angular/core';
import { ResearchModel } from '../research.model';
import { ResearchService } from '../research.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-research-detail',
  templateUrl: './research-detail.component.html',
  styleUrls: ['./research-detail.component.css']
})
export class ResearchDetailComponent implements OnInit {

  researchObject: ResearchModel = new ResearchModel('', '');
  isAuthenticated: boolean;

  constructor(
    private researchService: ResearchService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService) { }

  ngOnInit() {
    console.log('onInit of research-detail');
    this.isAuthenticated = this.authService.isAuthenticated();
    this.researchService.researchChanged.subscribe(
      (researchObject: ResearchModel) => {
        this.researchObject = researchObject;
        console.log(this.researchObject);
      }
    );
  }

  onEditResearch() {
    this.router.navigate(['/research/edit'], { relativeTo: this.route });
  }

  onHoverOverEditButton(el) {
    console.log('hovering over edit button');
    console.log(el);
  }

}
