export class NewsModel {
	public newsTitle: string;
	public datePosted: string;
	public description: string;

	constructor(newsTitle: string, date: string, desc: string) {
		this.newsTitle = newsTitle;
		this.datePosted = date;
		this.description = desc;
	}
}