import { Injectable, ɵConsole } from '@angular/core';
import { PublicationsModel } from './publications.model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PublicationsService {
  publicationsChanged = new Subject<PublicationsModel[]>();
  private publicationsArray: PublicationsModel[] = [];

  setPublications(publications: PublicationsModel[]) {
    console.log('SETTING PUBLICATIONS');

    if (!publications) {
      console.log('array from backend is empty or null');
      this.publicationsArray = [];
      this.publicationsChanged.next(this.publicationsArray.slice());
    } else {
      console.log('array from backend is NOT empty or null');
      this.publicationsArray = publications;
      this.publicationsChanged.next(publications.slice());
    }
  }

  getPublications(): PublicationsModel[] {
    console.log('GETTING PUBLICATIONS');
    console.log(this.publicationsArray);
    return this.publicationsArray.slice();
  }

  addPublication(publication: PublicationsModel) {
    console.log('ADDING PUBLICATION');
    this.publicationsArray.push(publication);
  }

  deletePublication(i: number) {
    console.log('DELETING PUBLICATION');
    this.publicationsArray.splice(i, 1);
    this.publicationsChanged.next(this.publicationsArray.slice());
    console.log('PUBLICATION DELETED');
    console.log(this.publicationsArray);
  }

  updatePublication(id: number, publication: PublicationsModel) {
    console.log('publication.service - updating publication');
    console.log(id);
    console.log(publication);
    this.publicationsArray[id] = publication;
  }
}
