import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { PeopleModel } from '../people.model';
import { PeopleService } from '../people.service';
import { DataStorageService } from 'src/app/shared/data-storage.service';
import { AuthService } from 'src/app/auth/auth.service';
import { FileUploadService } from 'src/app/file-upload/file-upload.service';
import { AngularFireStorageReference, AngularFireUploadTask, AngularFireStorage } from 'angularfire2/storage';
import { Router } from '@angular/router';
import { ConfirmationModalService } from 'src/app/confirmation-modal/confirmation-modal.service';

@Component({
  selector: 'app-add-people',
  templateUrl: './add-people.component.html',
  styleUrls: ['./add-people.component.css']
})
export class AddPeopleComponent implements OnInit {

  authToken: String = null;

  personForm: FormGroup;

  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  userChoseImageEvent: Event;


  constructor(
    private fb: FormBuilder,
    private peopleService: PeopleService,
    private dataStorageService: DataStorageService,
    private authService: AuthService,
    private fileUploadService: FileUploadService,
    private router: Router,
    private confirmationModalService: ConfirmationModalService
  ) {

  }

  ngOnInit() {
    this.createPersonForm();
  }

  createPersonForm() {
    this.personForm = this.fb.group({
      personType: 'faculty',
      firstName: '',
      lastName: '',
      currentPosition: '',
      researchAreaOfFocus: '',
      degrees: '',
      email: '',
      imageUrl: ''
    });
  }
   // first upload image to cloud firestore, get the url, add it to my form and send it to the database
  onSubmitPersonForm() {
    console.log('submitting prof form');
    console.log(this.personForm.value);

    if (!this.personForm.valid) {
      this.confirmationModalService.displayConfirmationModal('make sure required fields are not empty', 'failure', 2000);
      this.makeRequiredFieldsRed();
      return;
    }

    if (this.userChoseImageEvent) {
      const storageFolder = 'people/faculty';
      this.fileUploadService.upload(this.userChoseImageEvent, storageFolder)
        .then(uploadSnapshot => {
          uploadSnapshot.ref.getDownloadURL()
            .then(downloadUrl => {
              this.personForm.value.imageUrl = downloadUrl;
              this.onSaveProfForm();
            })
            .catch(error => {
              console.log(error);
              this.confirmationModalService.displayConfirmationModal('we were unable to upload your picture', 'failure', 2000);
            });
        })
        .catch(error => {
          console.log(error);
          this.confirmationModalService.displayConfirmationModal('we were unable to upload your picture', 'failure', 2000);
        });
    } else {
      // upload without image
      this.onSaveProfForm();
    }
  }

  makeRequiredFieldsRed() {
    console.log('inside makeRequiredFieldsRed()');
    const requiredInputs = document.getElementsByClassName('required-input');
    Array.from(requiredInputs).forEach(input => {
      input.classList.add('red-border');
    });
  }

  // saving the changes to the database
  onSaveProfForm() {
    const person: PeopleModel = <PeopleModel>this.personForm.value;
    this.peopleService.addPeople(person);
    this.dataStorageService.storePeople().subscribe(
      res => {
        console.log(res);
        this.dataStorageService.fetchPeople(); // after saving to database, fetch again to update front end
        this.confirmationModalService.displayConfirmationModal('user profile successfully added', 'success', 2000);
        this.personForm.reset();
        this.onCancel();
      },
      error => {
        console.log(error);
        this.confirmationModalService.displayConfirmationModal('Oops...we were unable to add this user\'s profile', 'failure', 2000);
        this.dataStorageService.fetchPeople();
      }
    );
  }

  // navigates user to /people endpoint
  onCancel() {
    this.router.navigate(['/people']);
  }

  // signals that the user has infact chosen an image to upload
  // But this comes hand in hand with the ability to edit their profile image
  onChooseImage($event) {
    console.log('user chose image');
    this.userChoseImageEvent = $event;
  }
}
