import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ResearchModel } from './research.model';

@Injectable({
    providedIn: 'root'
})
export class ResearchService {
    researchChanged = new Subject<ResearchModel>();
    researchObject: ResearchModel = new ResearchModel('', '');

    setResearchObject(researchObject) {
        console.log('setting research object -- ');
        this.researchObject = researchObject;
        this.researchChanged.next(researchObject);
        console.log('setResearchObject -- ');
        console.log(this.researchObject);
    }

    getResearchObject() {
        console.log('getting research object -- ');
        console.log(this.researchObject);
        return this.researchObject;
    }
}
