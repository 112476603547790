import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../header/header.service';

@Component({
  selector: 'app-dscams',
  templateUrl: './dscams.component.html',
  styleUrls: ['./dscams.component.css']
})
export class DscamsComponent implements OnInit {

  constructor(
    private headerService: HeaderService
  ) { }

  ngOnInit() {
    this.headerService.removePreviouslyActiveNavElement();
    this.headerService.setActiveNavElement('.dscams');
  }

}
