import { Component, OnInit, Input } from '@angular/core';
import { AccordionService } from './accordion.service';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.css']
})
export class AccordionComponent implements OnInit {

  accordionData: any = <any>{};
  get keys() {
    return Object.keys(this.accordionData);
  }

  test = {
    test1: ['tes1 1', 'tes1 2'],
    test2: ['test2 2', 'test2 2']
  };

  constructor(
    private accordionService: AccordionService
  ) { }

  ngOnInit() {
    console.log(this.accordionService.getAccordionData());
    this.onAccordionDataChanged();
  }

  onAccordionDataChanged() {
    this.accordionService.accordionDataChanged.subscribe(
      (accordionData) => {
        console.log(accordionData);
        this.accordionData = accordionData;
      }
    );
  }

}
