import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationModalService {

  private submissionConfirmationMessage: String = '';
  submissionConfirmationMessageChanged = new Subject<String>();
  constructor() { }

  getSubmissionConfirmationMessage() {
    return this.submissionConfirmationMessage;
  }

  setSubmissionConfirmationMessage(msg: String) {
    this.submissionConfirmationMessage = msg.toLocaleLowerCase();
    this.submissionConfirmationMessageChanged.next(msg.slice());
  }

  // outcome is either success -> display green modal or failure -> display red modal
  // time is the amount of time to display it for
  displayConfirmationModal(msg: String, outcome: String, time: number) {
    this.setSubmissionConfirmationMessage(msg);
    const confirmationModalElement = document.getElementById('confirmation-modal');
    confirmationModalElement.classList.remove('hidden');

    if (outcome === 'success') {
      confirmationModalElement.classList.add('success-confirmation');
    } else if (outcome === 'failure') {
      confirmationModalElement.classList.add('failure-confirmation');
    }
    // else it's a programming error

    this.makeModalDisappear(confirmationModalElement, time);
  }

  makeModalDisappear(confirmationModalElement: HTMLElement, time: number) {
    setTimeout(() => {
      confirmationModalElement.className = '';
      confirmationModalElement.classList.add('hidden');
    }, time);
  }
}
