import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import { Subject } from 'rxjs';
import { ConfirmationModalService } from '../confirmation-modal/confirmation-modal.service';

@Injectable()
export class AuthService {

  token: string;
  tokenChanged = new Subject<String>();

  constructor(
    private router: Router,
    private confirmationModalService: ConfirmationModalService) { }

  signInUser(email: string, password: string) {
    firebase.auth().signInWithEmailAndPassword(email, password)
      .then(
        response => {
          this.router.navigate(['/']);
          firebase.auth().currentUser.getIdToken()
            .then(
              (token: string) => {
                this.token = token;
                this.tokenChanged.next(token);
              }
            );
        }
      )
      .catch(
        error => {
          console.log(error);
          this.confirmationModalService.displayConfirmationModal('invalid username/password', 'failure', 2000);
        }
      );
  }

  logout() {
    firebase.auth().signOut();
    this.token = null;
    window.location.reload();
  }

  getToken() {
    if (firebase.auth().currentUser == null) {
      return null;
    }
    firebase.auth().currentUser.getIdToken()
      .then(
        (token: string) => this.token = token
      );
    return this.token;
  }

  // TODO: display green message when signed in, red when signed out for 1 sec
  isAuthenticated() {
    return this.token != null;
  }
}
