import { Component, OnInit } from '@angular/core';
import { ConfirmationModalService } from './confirmation-modal.service';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.css']
})
export class ConfirmationModalComponent implements OnInit {

  submissionConfirmationMessage: String;
  constructor(private confirmationModalService: ConfirmationModalService) { }

  ngOnInit() {
    this.confirmationModalService.submissionConfirmationMessageChanged
      .subscribe(msg => {
        this.submissionConfirmationMessage = msg;
      });
  }
}
