import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-click-to-edit-div',
  templateUrl: './click-to-edit-div.component.html',
  styleUrls: ['./click-to-edit-div.component.css']
})
export class ClickToEditDivComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
