import { Component, OnInit, Input } from '@angular/core';

import { NewsModel } from '../news.model';
import { NewsService } from '../news.service';
import { DataStorageService } from '../../shared/data-storage.service';

@Component({
  selector: 'app-news-start',
  templateUrl: './news-start.component.html',
  styleUrls: ['./news-start.component.css']
})
export class NewsStartComponent implements OnInit {

  newsArray: NewsModel[];

  constructor(private newsService: NewsService,
    private dataStorageService: DataStorageService) { }

  ngOnInit() {
    this.newsService.newsChanged
      .subscribe(
        (newsArray: NewsModel[]) => {
          this.newsArray = newsArray;
        }
      );
    this.newsArray = this.newsService.getNews();
  }

}
