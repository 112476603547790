import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-plus-sign',
  templateUrl: './plus-sign.component.html',
  styleUrls: ['./plus-sign.component.css']
})
export class PlusSignComponent implements OnInit {

  @Input()
  tooltipTitle: String;

  constructor() { }

  ngOnInit() {
  }

}
