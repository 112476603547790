import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import * as firebase from 'firebase';
import { AuthService } from './auth/auth.service';
import { AngularFireStorage } from 'angularfire2/storage';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {

  isAuthenticated: boolean;
  token: String;

  constructor(
    private router: Router,
    private authService: AuthService,
    private afStorage: AngularFireStorage) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        (<any>window).ga('set', 'page', event.urlAfterRedirects);
        (<any>window).ga('send', 'pageview');
      }
    });
  }

  ngOnInit() {
    this.authService.tokenChanged.subscribe(
      (token) => {
        console.log('token changed. you are now signed in or signed out');
        this.token = token;
      }
    );
  }
}


