import { Component, OnInit, ElementRef, Renderer2, ViewChild, AfterViewInit } from '@angular/core';
import { PeopleModel } from '../people.model';
import { PeopleService } from '../people.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DataStorageService } from 'src/app/shared/data-storage.service';
import { ConfirmationModalService } from 'src/app/confirmation-modal/confirmation-modal.service';
import { AuthService } from 'src/app/auth/auth.service';
import { AccordionService } from 'src/app/accordion/accordion.service';

@Component({
  selector: 'app-people-list',
  templateUrl: './people-list.component.html',
  styleUrls: ['./people-list.component.css']
})
export class PeopleListComponent implements OnInit {
  peopleArray: PeopleModel[];
  unsavedChangesExist: boolean;
  isAuthenticated: boolean;

  facultyArray: PeopleModel[] = [];
  undergraduateStudentArray: PeopleModel[] = [];
  alumniArray: PeopleModel[] = [];

  accordionData: any = <any>{};

  @ViewChild('test') el: ElementRef;
  constructor(
    private peopleService: PeopleService,
    private router: Router,
    private dataStorageService: DataStorageService,
    private confirmationModalService: ConfirmationModalService,
    private authService: AuthService,
    private accordionService: AccordionService,
    private rd: Renderer2,
    private _elementRef: ElementRef
  ) {
    console.log(this.unsavedChangesExist);
  }

  ngOnInit() {
    this.isAuthenticated = this.authService.isAuthenticated();
    this.unsavedChangesExist = false;
    console.log(this.peopleArray);
    this.onPeopleChanged();
    console.log(this.accordionData);

    this.ModifyDOMElement();
  }

  ModifyDOMElement(): void {
    const domElement = this._elementRef.nativeElement.querySelector(`#test`);
    console.log(domElement);
  }

  populateAccordionData() {
    this.accordionData.faculty = this.facultyArray;
    this.accordionData.undergraduateStudent = this.undergraduateStudentArray;
    this.accordionData.alumni = this.alumniArray;
  }

  onPeopleChanged() {
    this.peopleService.peopleChanged.subscribe(
      (peopleArray: PeopleModel[]) => {
        console.log(peopleArray);
        this.peopleArray = peopleArray;
        this.populateArrays(peopleArray);
        this.populateAccordionData();
        this.accordionService.setAccordionData(this.accordionData);
      }
    );
  }

  populateArrays(peopleArray) {
    peopleArray.forEach(person => {
      if (person.personType === 'faculty') {
        this.facultyArray.push(person);
      } else if (person.personType === 'undergraduate-student') {
        this.undergraduateStudentArray.push(person);
      } else if (person.personType === 'alumni') {
        this.alumniArray.push(person);
      }
    });
  }

  // navigate user to /people/add or /people depending on their current location
  onToggleAddPerson() {
    if (this.router.url === '/people/add') {
      this.router.navigate(['./people']);
    } else {
      this.router.navigate(['./people/add']);
    }
  }

  checkAuth() {
    console.log('inside check aut');
    console.log(this.isAuthenticated);
    return this.isAuthenticated;
  }

  // TODO: delete picture from datastore here
  deletePerson(i: number) {
    this.peopleService.deletePeople(i);
    this.unsavedChangesExist = true;
    console.log(this.unsavedChangesExist);
  }

  onSubmit() {
    console.log(this.peopleService.getPeople());
    console.log('saving data');
    this.dataStorageService.storePeople()
      .subscribe(res => {
        console.log(res);
        console.log(this.peopleService.getPeople());
        this.dataStorageService.fetchPeople();
        this.unsavedChangesExist = false;
        this.confirmationModalService.displayConfirmationModal('changes saved successfully', 'success', 2000);
      }, error => {
        console.log(error);
        this.unsavedChangesExist = false;
        this.dataStorageService.fetchPeople();
        this.confirmationModalService.displayConfirmationModal('we were unable to save your changes', 'failure', 2000);
      });
  }

  // fetches data from database (to overwrite and ignore current changes)
  onCancel() {
    this.dataStorageService.fetchPeople();
    this.unsavedChangesExist = false;
  }

  isAlumni(el: PeopleModel) {
    return el.personType === 'alumni';
  }

  isFaculty(el: PeopleModel) {
    return el.personType === 'faculty';
  }

  isUndergraduateStudent(el: PeopleModel) {
    return el.personType === 'undergraduate-student';
  }

  changeMade() {
    console.log(this.unsavedChangesExist);
    return this.unsavedChangesExist;
  }

  onEditPerson(id: number) {
    console.log('onEditPerson(i)');
    this.router.navigate([`people/${id}/edit`]);
    console.log('why isnt it working');
  }
}
