import { Component, OnInit, Input } from '@angular/core';

import { NewsModel } from '../../news.model';

@Component({
  selector: 'app-news-item',
  templateUrl: './news-item.component.html',
  styleUrls: ['./news-item.component.css']
})
export class NewsItemComponent implements OnInit {

  @Input() newsPost: NewsModel;
  @Input() index: number;

  ngOnInit() {
  }
}
