import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Response } from '@angular/http';
import { NewsService } from '../news.service';
import { DataStorageService } from '../../shared/data-storage.service';
import { NewsModel } from '../news.model';

@Component({
	selector: 'app-news-edit',
	templateUrl: './news-edit.component.html',
	styleUrls: ['./news-edit.component.css']
})
export class NewsEditComponent implements OnInit {
	id: number;
	editMode = false;
	newsForm: FormGroup;

	constructor(private route: ActivatedRoute,
		private newsService: NewsService,
		private router: Router,
		private dataStorageService: DataStorageService) { }

	ngOnInit() {
		this.route.params
			.subscribe(
				(params: Params) => {
					this.id = +params['id'];
					this.editMode = params['id'] != null;
					this.initForm();
				}
			);
	}

	onSubmit() {
		if (this.editMode) {
			this.newsService.updateNews(this.id, this.newsForm.value);
		} else {
			this.newsService.addNews(this.newsForm.value);
		}
		this.onSaveData();
		this.onCancel();
	}

	onSaveData() {
		this.dataStorageService.storeNews()
			.subscribe(
				(response: Response) => {
					console.log(response);
				}
			);
	}

	onCancel() {
		this.router.navigate(['../'], { relativeTo: this.route });
	}

	private initForm() {
		let nTitle = '';
		let nDate = '';
		let nDescription = '';

		if (this.editMode) {
			const news = this.newsService.getNewsPost(this.id);
			nTitle = news.newsTitle;
			nDate = news.datePosted;
			nDescription = news.description;
		}

		this.newsForm = new FormGroup({
			'newsTitle': new FormControl(nTitle, Validators.required),
			'datePosted': new FormControl(nDate, Validators.required),
			'description': new FormControl(nDescription, Validators.required),
		});
	}

}
