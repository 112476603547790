import { Injectable } from '@angular/core';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from 'angularfire2/storage';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  downloadUrl: string;
  downloadUrlChanged: Observable<String>;

  constructor(private afStorage: AngularFireStorage) { }

  /* returns a promise of uploadTask */
  upload($event, storageFolder: String) {
    console.log('uploading pic');
    const fileName = $event.target.files[0].name;
    const id = Math.random().toString(36).substring(2);
    this.ref = this.afStorage.ref(`${storageFolder}/${fileName}__${id}`);
    return this.task = this.ref.put($event.target.files[0]);
  }
}
