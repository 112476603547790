import { Component, OnInit } from '@angular/core';
import { PeopleModel } from './people.model';
import { DataStorageService } from '../shared/data-storage.service';
import { HeaderService } from '../header/header.service';

@Component({
  selector: 'app-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.css']
})
export class PeopleComponent implements OnInit {

  constructor(
    private dataStorageService: DataStorageService,
    private headerService: HeaderService
    ) { }

  ngOnInit() {
    this.dataStorageService.fetchPeople();
    this.headerService.removePreviouslyActiveNavElement();
    this.headerService.setActiveNavElement('.people');
  }

}
