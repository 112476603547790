import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-minus-sign',
  templateUrl: './minus-sign.component.html',
  styleUrls: ['./minus-sign.component.css']
})
export class MinusSignComponent implements OnInit {

  @Input()
  tooltipTitle: String;

  constructor() { }

  ngOnInit() {
  }

}
