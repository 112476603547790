import { Component, OnInit, Input } from '@angular/core';
import { NewsModel } from '../../news.model';

@Component({
  selector: 'app-news-detail-list',
  templateUrl: './news-detail-list.component.html',
  styleUrls: ['./news-detail-list.component.css']
})
export class NewsDetailListComponent implements OnInit {

  @Input() newsPost: NewsModel;
  @Input() index: number;

  constructor() { }

  ngOnInit() {
  }

}
