import { Component, OnInit } from '@angular/core';
import { DataStorageService } from '../shared/data-storage.service';
import { PublicationsService } from './publications.service';
import { PublicationsModel } from './publications.model';
import { HeaderService } from '../header/header.service';

@Component({
  selector: 'app-publications',
  templateUrl: './publications.component.html',
  styleUrls: ['./publications.component.css']
})
export class PublicationsComponent implements OnInit {

  constructor(
    private dataStorageService: DataStorageService,
    private publicationsService: PublicationsService,
    private headerService: HeaderService
  ) { }

  ngOnInit() {
    console.log('INSIDE ONINIT - PUBLICATIONS COMPONENT');
    this.headerService.removePreviouslyActiveNavElement();
    this.headerService.setActiveNavElement('.publications');
    this.fetchPublications();
  }

  fetchPublications() {
    this.dataStorageService.fetchPublications()
      .subscribe(res => {
        console.log(res);
        console.log(res.json());
        const publications: PublicationsModel[] = res.json();
        this.publicationsService.setPublications(publications);
      }, err => {
        console.log(err);
      });
  }

}
