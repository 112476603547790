import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  setActiveNavElement(currentTabClassSelector: string) {
    console.log('inside setActiveNavElement');
    const navItemDiv = document.querySelector(`${currentTabClassSelector} .nav-item-div`);
    console.log(navItemDiv);
    navItemDiv.classList.remove('normal');
    navItemDiv.classList.add('current-active-tab', 'cursor-default');
  }

  removePreviouslyActiveNavElement() {
    console.log('remove ative nav element');
    const prevouslyActive = document.querySelector('.nav-item-div.current-active-tab ');
    console.log(prevouslyActive);
    if (prevouslyActive) {
      prevouslyActive.classList.remove('current-active-tab', 'cursor-default');
      prevouslyActive.classList.add('normal');
    }
  }
}
