import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccordionService {

  accordionData: any = <any>{};
  accordionDataChanged = new Subject<any>();

  constructor() { }

  setAccordionData(accordionData) {
    console.log('SETTING ACCORDION DATA');
    this.accordionData = accordionData;
    this.accordionDataChanged.next(this.accordionData);
    console.log('SET ACCORDION DATA TO: ');
    console.log(this.accordionData);
  }

  getAccordionData() {
    return this.accordionData;
  }

}
