import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-check-mark',
  templateUrl: './check-mark.component.html',
  styleUrls: ['./check-mark.component.css']
})
export class CheckMarkComponent implements OnInit {

  @Input()
  tooltipTitle: String;

  constructor() { }

  ngOnInit() {
  }

}
