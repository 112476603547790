import { NgModule } from '@angular/core';
import { Routes, RouterModule, ChildActivationEnd } from '@angular/router';

import { PeopleComponent } from './people/people.component';
import { ResearchComponent } from './research/research.component';
import { PublicationsComponent } from './publications/publications.component';
import { NewsComponent } from './news/news.component';
import { ContactComponent } from './contact/contact.component';
import { HomepageComponent } from './homepage/homepage.component';
import { NewsStartComponent } from './news/news-start/news-start.component';
import { NewsDetailComponent } from './news/news-detail/news-detail.component';
import { NewsEditComponent } from './news/news-edit/news-edit.component';
import { SigninComponent } from './auth/signin/signin.component';
import { AuthGuard } from './auth/auth-guard.service';
import { AddPeopleComponent } from './people/add-people/add-people.component';
import { OpportunitiesEditComponent } from './opportunities/opportunities-edit/opportunities-edit.component';
import { OpportunitiesComponent } from './opportunities/opportunities.component';
import { ResearchEditComponent } from './research/research-edit/research-edit.component';
import { PublicationsEditComponent } from './publications/publications-edit/publications-edit.component';
import { PublicationsListComponent } from './publications/publications-list/publications-list.component';
import { TestComponent } from './test/test.component';
import { OpportunitiesListComponent } from './opportunities/opportunities-list/opportunities-list.component';
import { PeopleEditComponent } from './people/people-edit/people-edit.component';
import { DscamsComponent } from './dscams/dscams.component';

const appRoutes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomepageComponent },

  {
    path: 'people', children: [
      {
        path: '', component: PeopleComponent, children: [
          { path: 'add', component: AddPeopleComponent, canActivate: [AuthGuard] }
        ]
      },
      { path: ':id/edit', component: PeopleEditComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'opportunities/:id/edit', component: OpportunitiesEditComponent, pathMatch: 'full', canActivate: [AuthGuard] }
    ]
  },

  { path: 'research', component: ResearchComponent },
  { path: 'research/edit', component: ResearchEditComponent, canActivate: [AuthGuard] },
  { path: 'publications', component: PublicationsComponent },
  { path: 'publications/:id/edit', component: PublicationsEditComponent, canActivate: [AuthGuard] },

  {
    path: 'news', component: NewsComponent, children: [
      { path: '', component: NewsStartComponent },
      { path: 'new', component: NewsEditComponent, canActivate: [AuthGuard] },
      { path: ':id', component: NewsDetailComponent },
      { path: ':id/edit', component: NewsEditComponent, canActivate: [AuthGuard] }
    ]
  },
  { path: 'dscams', component: DscamsComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'signin', component: SigninComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
