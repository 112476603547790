import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { OpportunitiesModel } from './opportunities.model';

@Injectable({
  providedIn: 'root'
})
export class OpportunitiesService {
  opportunitiesChanged = new Subject<OpportunitiesModel[]>();

  private opportunitiesArray: OpportunitiesModel[] = [];

  setOpportunities(opportunitiesArray: OpportunitiesModel[]) {
    console.log('setting opportunities');
    this.opportunitiesArray = opportunitiesArray;
    this.opportunitiesChanged.next(this.opportunitiesArray);
    console.log('set opportunities');
    console.log(this.opportunitiesArray);
  }

  getOpportunities() {
    console.log('getting opportunities');
    return this.opportunitiesArray.slice();
  }

  updateOpportunity(id: number, opportunity: OpportunitiesModel) {
    console.log('opportunities.service - updateOpportunity()');
    this.opportunitiesArray[id] = opportunity;
  }

  addOpportunitiy(opp: OpportunitiesModel) {
    console.log('adding opportunities');
    this.opportunitiesArray.push(opp);
  }

  deleteOpportunity(i: number) {
    console.log('deleting opportunity');
    console.log(this.opportunitiesArray[i]);
    this.opportunitiesArray.splice(i, 1);
    this.opportunitiesChanged.next(this.opportunitiesArray);
  }
}
