export class PeopleModel {
  public personType: String;
  public firstName: String;
  public lastName: String;
  public currentPosition: String;
  public researchAreaOfFocus: String; // only for faculty
  public degrees: String;
  public email: String;
  public imageUrl: String;

  constructor(
    personType: String,
    firstName: String,
    lastName: String,
    currentPosition: String,
    researchAreaOfFocus: String, // only for faculty
    degrees: String,
    email: String,
    imageUrl: String) {
      this.personType = personType;
      this.firstName = firstName;
      this.lastName = lastName;
      this.currentPosition = currentPosition;
      this.researchAreaOfFocus = researchAreaOfFocus;
      this.degrees = degrees;
      this.email = email;
      this.imageUrl = imageUrl;
  }
}
