import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PublicationsService } from '../publications.service';
import { PublicationsModel } from '../publications.model';
import { DataStorageService } from 'src/app/shared/data-storage.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ConfirmationModalService } from 'src/app/confirmation-modal/confirmation-modal.service';

@Component({
  selector: 'app-publications-edit',
  templateUrl: './publications-edit.component.html',
  styleUrls: ['./publications-edit.component.css']
})
export class PublicationsEditComponent implements OnInit {

  publication: PublicationsModel = null;
  editPublicationForm: FormGroup;
  id: number;

  Editor = ClassicEditor;
  EditorConfig = {
    toolbar: ['Heading', 'Bold', 'Italic', 'Link', 'BulletedList', 'NumberedList', 'Undo', 'Redo']
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private publicationsService: PublicationsService,
    private dataStorageService: DataStorageService,
    private fb: FormBuilder,
    private confirmationModalService: ConfirmationModalService
  ) { }

  ngOnInit() {
    console.log('ngOnInit of PUBLICATIONS EDIT');
    this.initForm();
    this.getIdParam();
  }

  // gets the id of the publication currently being edited
  getIdParam() {
    this.route.paramMap.subscribe(parameterMap => {
      console.log(parameterMap);
      this.id = +parameterMap.get('id');
      this.onPublicationChanged();
      this.fetchPublications();
    });
  }

  fetchPublications() {
    this.dataStorageService.fetchPublications()
      .subscribe(res => {
        const publications: PublicationsModel[] = res.json();
        this.publicationsService.setPublications(publications);
      }, err => {
        console.log(err);
        this.confirmationModalService.displayConfirmationModal('possible problem with the database. Contact engineer', 'failure', 5000);
      });
  }

  // triggers a re-render when a publication changes. i.e. when the fetch promise if fulfilled
  onPublicationChanged() {
    console.log('inside onPublicationChanged');
    this.publicationsService.publicationsChanged.subscribe(
      (publications: PublicationsModel[]) => {
        this.publication = publications[this.id];
        console.log(this.publication);
        // update form
        this.editPublicationForm = this.fb.group({
          body: this.publication.body,
          imageUrl: this.publication.imageUrl
        });
      });
  }

  // initiallizes form
  initForm() {
    console.log(this.publication);
    console.log('publications-edit.ts - initializing form');
    this.editPublicationForm = this.fb.group({
      body: ''
    });
  }

  onSaveChanges() {
    console.log('saving changes');
    this.publicationsService.updatePublication(this.id, this.publication);
  }

  onCancel() {
    console.log('cancelling');
    this.router.navigate(['/publications']);
  }

  storePublications() {
    this.dataStorageService.storePublications().subscribe(
      res => {
        console.log(res);
        this.confirmationModalService.displayConfirmationModal('publication saved', 'success', 2000);
        this.onCancel();
      }, err => {
        console.log(err);
        this.confirmationModalService.displayConfirmationModal('error: possible problem with \
        the database. contact engineer', 'failure', 5000);
      });
  }

  onSubmit() {
    console.log('submitting publication');
    console.log(this.editPublicationForm.value);
    this.publication = this.editPublicationForm.value;
    if (this.publication.body.length === 0) {
      this.confirmationModalService.displayConfirmationModal('publication body cannot be empty', 'failure', 2000);
      return;
    }
    this.onSaveChanges();
    this.storePublications();
  }
}
