import { Component, OnInit } from '@angular/core';
import { PublicationsModel } from '../publications.model';
import { PublicationsService } from '../publications.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DataStorageService } from 'src/app/shared/data-storage.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFireStorageReference, AngularFireUploadTask } from 'angularfire2/storage';
import { AuthService } from 'src/app/auth/auth.service';
import { FileUploadService } from 'src/app/file-upload/file-upload.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ConfirmationModalService } from 'src/app/confirmation-modal/confirmation-modal.service';

@Component({
  selector: 'app-publications-list',
  templateUrl: './publications-list.component.html',
  styleUrls: ['./publications-list.component.css']
})
export class PublicationsListComponent implements OnInit {

  isAuthenticated: boolean;
  unsavedChangesExist: boolean;

  publications: PublicationsModel[];
  addPublicationFormIsShowing: boolean;
  addPublicationForm: FormGroup;
  idToDelete: number = null;

  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  userChoseImageEvent: Event;

  Editor = ClassicEditor;
  EditorConfig = {
    toolbar: ['Heading', 'Bold', 'Italic', 'Link', 'BulletedList', 'NumberedList', 'Undo', 'Redo'],
    placehlder: 'Enter your publication'
  };

  constructor(
    private publicationsService: PublicationsService,
    private fb: FormBuilder,
    private dataStorageService: DataStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private fileUploadService: FileUploadService,
    private confirmationModalService: ConfirmationModalService,
    private authService: AuthService) { }

  ngOnInit() {
    console.log('ON INIT - PUBLICATIONS-LIST');
    this.isAuthenticated = this.authService.isAuthenticated();
    this.unsavedChangesExist = false;
    this.addPublicationFormIsShowing = false;
    this.initForm();
    this.onPublicationsChanged();
  }

  initForm() {
    console.log('initiating publications form');
    this.addPublicationForm = this.fb.group({
      body: '',
      imageUrl: ''
    });
  }

  // triggers a re-render everytime a value in the array of publications changes
  onPublicationsChanged() {
    console.log('onPublicationsChanged called');
    this.publicationsService.publicationsChanged.subscribe(
      (publications: PublicationsModel[]) => {
        console.log('BEFORE UPDATING PUBLICATIONS[] THROUGH SUBJECT');
        console.log(this.publications);
        this.publications = publications;
        console.log('AFTER UPDATING PUBLICATIONS[] THROUGH SUBJECT');
        console.log(this.publications);
      });
  }

  onAddPublication() {
    const publicationBody = this.addPublicationForm.value.body.trim();
    if (publicationBody.length < 1) {
      this.confirmationModalService.displayConfirmationModal('description cannot be empty', 'failure', 2000);
      return;
    }

    if (this.userChoseImageEvent) {
      console.log('submitting new publication');
      this.handleUploadOfBookCoverImage()
        .then(uploadSnapshot => {
          uploadSnapshot.ref.getDownloadURL()
            .then(downloadUrl => {
              console.log(downloadUrl);
              this.updateImageUrlInPublicationForm(downloadUrl);
              this.addPublicationForm.value.body = publicationBody;
              console.log(this.addPublicationForm.value);
              this.publicationsService.addPublication(this.addPublicationForm.value);
              this.onSaveChanges();
            });
        });
    } else {
      this.addPublicationForm.value.body = publicationBody;
      this.publicationsService.addPublication(this.addPublicationForm.value);
      this.onSaveChanges();
    }
  }

  toggleAddPublicationForm() {
    this.addPublicationFormIsShowing = !this.addPublicationFormIsShowing;
  }

  // TODO: personal challenge - Do edit in place
  onEditPublication(i: number) {
    console.log('Can now edit publication');
    this.router.navigate(['/publications/' + i + '/edit'], { relativeTo: this.route });
  }

  onDeletePublication(i: number) {
    console.log('onDeletePublication');
    this.publicationsService.deletePublication(i);
    this.unsavedChangesExist = true;
  }

  onCancelChanges() {
    this.fetchPulications();
    this.unsavedChangesExist = false;
  }

  // sends updated publications to the backend. The updated backend is fetched again updates the UI
  onSaveChanges() {
    console.log('saving changes');
    this.dataStorageService.storePublications()
      .subscribe(res => { // succesfully store publications in database
        console.log(res);
        this.confirmationModalService.displayConfirmationModal('change saved successfully', 'success', 2000);
        this.unsavedChangesExist = false;
        this.addPublicationForm.reset();
        this.userChoseImageEvent = null;
        this.fetchPulications();
      }, err => { // could not store publications to database
        this.addPublicationFormIsShowing = false;
        this.confirmationModalService.displayConfirmationModal('we were unable to save your changes', 'failure', 2000);
        this.unsavedChangesExist = false;
        this.fetchPulications();
      });
  }

  fetchPulications() {
    this.dataStorageService.fetchPublications().subscribe(
      resp => {
        console.log(resp);
        const pubs = resp.json();
        this.publicationsService.setPublications(pubs);
        this.addPublicationFormIsShowing = false;
      }, err => { // but problem fetching
        this.confirmationModalService.displayConfirmationModal('something unexpected happened', 'failure', 2000);
      }
    );
  }

  handleUploadOfBookCoverImage() {
    console.log('publications-list.ts - handleUploadOfBookCoverImage');
    const storageFolder = 'publications';
    return this.fileUploadService.upload(this.userChoseImageEvent, storageFolder);
  }

  updateImageUrlInPublicationForm(downloadUrl) {
    console.log('updating image of url in form');
    this.addPublicationForm.value.imageUrl = downloadUrl;
  }

  // signals that the user has chosen an image to upload
  onChooseImage($event) {
    console.log('user chose image');
    this.userChoseImageEvent = $event;
  }

  magnifyBookCover(i: number) {
    console.log(i);
    const bookCoverDiv = document.getElementsByClassName('book-covers-div')[i];
    const img = bookCoverDiv.querySelector('div > div > img');
    console.log(img);
    if (img) {
      img.classList.add('magnified-img');
    }
  }

  revertToOriginalImageSize(i: number) {
    const bookCoverDiv = document.getElementsByClassName('book-covers-div')[i];
    const img = bookCoverDiv.querySelector('div > div > img');
    console.log(img);

    if (img) {
      img.classList.remove('magnified-img');
    }
  }
}
