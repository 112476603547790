import { Component, OnInit } from '@angular/core';
import { PeopleModel } from '../people.model';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { PeopleService } from '../people.service';
import { DataStorageService } from 'src/app/shared/data-storage.service';
import { ConfirmationModalService } from 'src/app/confirmation-modal/confirmation-modal.service';

@Component({
  selector: 'app-people-edit',
  templateUrl: './people-edit.component.html',
  styleUrls: ['./people-edit.component.css']
})
export class PeopleEditComponent implements OnInit {

  person: PeopleModel = new PeopleModel('', '', '', '', '', null, '', '');
  editPersonForm: FormGroup;
  id: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private peopleService: PeopleService,
    private dataStorageService: DataStorageService,
    private fb: FormBuilder,
    private confirmationModalService: ConfirmationModalService
  ) { }

  ngOnInit() {
    console.log('ngOnInit');
    this.initForm();
    this.getIdParam();
  }

  initForm() {
    console.log('initForm()');
    this.editPersonForm = this.fb.group({
      personType: 'faculty',
      firstName: '',
      lastName: '',
      currentPosition: '',
      researchAreaOfFocus: '',
      degrees: '',
      email: ''
    });
  }

  // gets the id of the person currently being edited
  getIdParam() {
    this.route.paramMap.subscribe(parameterMap => {
      console.log(parameterMap);
      this.id = +parameterMap.get('id');
      this.onPersonChanged();
      this.fetchPeople();
    });
  }

  onPersonChanged() {
    console.log('onPersonChanged()');
    this.peopleService.peopleChanged.subscribe(
      (peopleArray: PeopleModel[]) => {
        this.person = peopleArray[this.id];
        this.editPersonForm = this.fb.group({
          personType: this.person.personType,
          firstName: this.person.firstName,
          lastName: this.person.lastName,
          currentPosition: this.person.currentPosition,
          researchAreaOfFocus: this.person.researchAreaOfFocus,
          degrees: this.person.degrees,
          email: this.person.email,
          imageUrl: this.person.imageUrl
        });
        console.log(this.editPersonForm.value);
      }
    );
  }

  fetchPeople() {
    console.log('fetchPeople()');
    this.dataStorageService.fetchPeople();
  }

  onSubmit() {
    console.log(this.editPersonForm.value);
    this.peopleService.updatePeople(this.id, this.editPersonForm.value);
    this.dataStorageService.storePeople().subscribe(
      res => {
        console.log(res);
        this.confirmationModalService.displayConfirmationModal('user profile updated successfully', 'success', 2000);
        this.onCancelChanges();
      }, error => {
        console.log(error);
        this.confirmationModalService.displayConfirmationModal('we were unable to carry out this request at this time', 'failure', 2000);
      }
    );
  }

  // navigates user back to people component
  onCancelChanges() {
    this.router.navigate(['/people']);
  }

}
