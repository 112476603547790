import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { OpportunitiesService } from '../opportunities.service';
import { DataStorageService } from 'src/app/shared/data-storage.service';
import { OpportunitiesModel } from '../opportunities.model';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-opportunities-edit',
  templateUrl: './opportunities-edit.component.html',
  styleUrls: ['./opportunities-edit.component.css']
})
export class OpportunitiesEditComponent implements OnInit {
  private id: number;
  opportunityForm: FormGroup;
  opportunity: OpportunitiesModel;
  Editor = ClassicEditor;
  EditorConfig = {
    toolbar: ['Heading', 'Bold', 'Italic', 'Link', 'BulletedList', 'NumberedList', 'Undo', 'Redo']
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private opportunitiesService: OpportunitiesService,
    private dataStorageService: DataStorageService) { }

  ngOnInit() {
    console.log('opportunities-edit - inside ngOnInit');
    this.initForm();
    this.getIdParam();
  }

  getIdParam() {
    console.log('opportunities-edit - inside getIdParam()');
    this.route.params
      .subscribe((params: Params) => {
        this.id = +params['id'];
        this.fetchOpportunties();
      });
  }

  opportunitiesChanged() {
    console.log('opportunities-edit - inside onOpportunitiesChanged()');
    this.opportunitiesService.opportunitiesChanged.subscribe(
      (oppArray: OpportunitiesModel[]) => {
        this.opportunity = oppArray[this.id];
        this.opportunityForm = this.fb.group({
          title: this.opportunity.title,
          description: this.opportunity.description
        });
      }
    );
  }

  fetchOpportunties() {
    console.log('opportunities-edit - inside fetchOpportunities()');
    this.dataStorageService.fetchOpportunities().subscribe(
      (res) => {
        const oppArray: OpportunitiesModel[] = res.json();
        this.opportunitiesChanged();
        this.opportunitiesService.setOpportunities(oppArray);
      }
    );
  }

  initForm() {
    console.log('inside initForm()');
    this.opportunityForm = this.fb.group({
      title: '',
      description: ''
    });
  }

  onCancel() {
    console.log('fsd');
    this.router.navigate(['/people']);
  }

  storeOpportunities() {
    console.log('opportunities-edit - opportunities ')
    this.dataStorageService.storeOpportunities().subscribe(
      res => {
        console.log(res);
        this.onCancel();
      },
      err => {
        console.log(err);
      }
    );
  }

  onSubmit() {
    console.log('opportunities-edit - onSubmit()');
    this.opportunitiesService.updateOpportunity(this.id, this.opportunityForm.value);
    this.storeOpportunities();
  }
}
