import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { NewsModel } from './news.model';

@Injectable()
export class NewsService {
  newsChanged = new Subject<NewsModel[]>();

  private newsArray: NewsModel[] = [];

  setNews(newsArray: NewsModel[]) {
    this.newsArray = newsArray;
    this.newsChanged.next(this.newsArray.slice());
  }

  getNews() {
    if (this.newsArray === null || this.newsArray === undefined) {
      return null;
    }
    return this.newsArray.slice();
  }

  getNewsPost(index: number) {
    return this.newsArray[index];
  }

  addNews(newsPost: NewsModel) {
    this.newsArray.unshift(newsPost);
    this.newsChanged.next(this.newsArray.slice());
  }

  updateNews(index: number, editedNews: NewsModel) {
    this.newsArray[index] = editedNews;
    this.newsChanged.next(this.newsArray.slice());
  }

  deleteNews(index: number) {
    this.newsArray.splice(index, 1);
    this.newsChanged.next(this.newsArray.slice());
  }
}
