import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Response } from '@angular/http';

import { NewsModel } from '../news.model';
import { NewsService } from '../news.service';
import { DataStorageService } from '../../shared/data-storage.service';
import { AuthService } from '../../auth/auth.service';
@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.css']
})
export class NewsListComponent implements OnInit {
  newsArray: NewsModel[];

  constructor(private newsService: NewsService,
    private router: Router,
    private route: ActivatedRoute,
    private dataStorageService: DataStorageService,
    public authService: AuthService) { }

  ngOnInit() {
    this.newsService.newsChanged
      .subscribe(
        (newsArray: NewsModel[]) => {
          this.newsArray = newsArray;
        }
      );
    this.newsArray = this.newsService.getNews();
    console.log(this.newsArray);
  }

  onSave() {
    this.dataStorageService.storeNews()
      .subscribe(
        (response: Response) => {
          console.log(response);
        }
      );
  }

  onNewNews() {
    console.log('new news');
    this.router.navigate(['news/new']);
  }
}
