import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { AppComponent } from './app.component';
import { SafeHTMLPipe } from './safeHTML.pipe';
import { HeaderComponent } from './header/header.component';
import { PeopleComponent } from './people/people.component';
import { ResearchComponent } from './research/research.component';
import { PublicationsComponent } from './publications/publications.component';
import { NewsComponent } from './news/news.component';
import { ContactComponent } from './contact/contact.component';
import { FooterComponent } from './footer/footer.component';
import { HomepageComponent } from './homepage/homepage.component';
import { NewsListComponent } from './news/news-list/news-list.component';
import { NewsDetailComponent } from './news/news-detail/news-detail.component';
import { NewsItemComponent } from './news/news-list/news-item/news-item.component';
import { DropdownDirective } from './shared/dropdown.directive';
import { NewsService } from './news/news.service';
import { NewsStartComponent } from './news/news-start/news-start.component';
import { NewsEditComponent } from './news/news-edit/news-edit.component';
import { DataStorageService } from './shared/data-storage.service';
import { SigninComponent } from './auth/signin/signin.component';
import { NewsDetailListComponent } from './news/news-start/news-detail-list/news-detail-list.component';
import { AuthService } from './auth/auth.service';
import { AuthGuard } from './auth/auth-guard.service';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PeopleListComponent } from './people/people-list/people-list.component';
import { AddPeopleComponent } from './people/add-people/add-people.component';
import { OpportunitiesComponent } from './opportunities/opportunities.component';
import { OpportunitiesListComponent } from './opportunities/opportunities-list/opportunities-list.component';
import { OpportunitiesEditComponent } from './opportunities/opportunities-edit/opportunities-edit.component';
import { ResearchEditComponent } from './research/research-edit/research-edit.component';

import { QuillModule } from 'ngx-quill';
import { ResearchDetailComponent } from './research/research-detail/research-detail.component';
import { PublicationsEditComponent } from './publications/publications-edit/publications-edit.component';
import { PublicationsListComponent } from './publications/publications-list/publications-list.component';
import { ModalComponent } from './UI/modal/modal.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TestComponent } from './test/test.component';

import { AngularFireModule } from 'angularfire2';
import { AngularFireStorageModule } from 'angularfire2/storage';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { PlusSignComponent } from './UI/buttons/plus-sign/plus-sign.component';
import { MinusSignComponent } from './UI/buttons/minus-sign/minus-sign.component';
import { CheckMarkComponent } from './UI/buttons/check-mark/check-mark.component';
import { RevertComponent } from './UI/buttons/revert/revert.component';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { PeopleEditComponent } from './people/people-edit/people-edit.component';
import { ClickToEditDivComponent } from './click-to-edit-div/click-to-edit-div.component';
import { AccordionComponent } from './accordion/accordion.component';
import { DscamsComponent } from './dscams/dscams.component';

@NgModule({
  declarations: [
    AppComponent,
    SafeHTMLPipe,
    HeaderComponent,
    PeopleComponent,
    ResearchComponent,
    PublicationsComponent,
    NewsComponent,
    ContactComponent,
    FooterComponent,
    HomepageComponent,
    NewsListComponent,
    NewsDetailComponent,
    NewsItemComponent,
    DropdownDirective,
    NewsStartComponent,
    NewsEditComponent,
    SigninComponent,
    NewsDetailListComponent,
    PeopleListComponent,
    AddPeopleComponent,
    OpportunitiesComponent,
    OpportunitiesListComponent,
    OpportunitiesEditComponent,
    ResearchEditComponent,
    ResearchDetailComponent,
    PublicationsEditComponent,
    PublicationsListComponent,
    ModalComponent,
    TestComponent,
    FileUploadComponent,
    ConfirmationModalComponent,
    PlusSignComponent,
    MinusSignComponent,
    CheckMarkComponent,
    RevertComponent,
    PeopleEditComponent,
    ClickToEditDivComponent,
    AccordionComponent,
    DscamsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    BrowserAnimationsModule,
    QuillModule,
    NgbModule.forRoot(),
    AngularFireModule.initializeApp({
      apiKey: 'AIzaSyCQVrbkDSa7w-gfJW1u3zJWjnYFE6oOYOA',
      authDomain: 'bclab-news-34abb.firebaseapp.com',
      projectId: 'bclab-news-34abb',
      storageBucket: 'bclab-news-34abb.appspot.com',
    }),
    AngularFireStorageModule,
    CKEditorModule
  ],
  providers: [NewsService, DataStorageService, AuthService, AuthGuard, { provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule { }
