import { Component, OnInit } from '@angular/core';
import { ResearchModel } from './research.model';
import { DataStorageService } from '../shared/data-storage.service';
import { ResearchService } from './research.service';
import { HeaderService } from '../header/header.service';

@Component({
  selector: 'app-research',
  templateUrl: './research.component.html',
  styleUrls: ['./research.component.css']
})
export class ResearchComponent implements OnInit {

  constructor(
    private dataStorageService: DataStorageService,
    private headerService: HeaderService
  ) { }

  ngOnInit() {
    console.log('onInit -- research component');
    this.dataStorageService.fetchResearch();
    this.headerService.removePreviouslyActiveNavElement();
    this.headerService.setActiveNavElement('.research');
  }
}
