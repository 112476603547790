import { Component, OnInit } from '@angular/core';

import { NewsService } from './news.service';
import { DataStorageService } from '../shared/data-storage.service';
import { NewsModel } from './news.model';
import { HeaderService } from '../header/header.service';

@Component({
  selector: 'app-notes',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

  constructor(
    private dataStorageService: DataStorageService,
    private newsService: NewsService,
    private headerService: HeaderService) { }

  ngOnInit() {
    this.headerService.removePreviouslyActiveNavElement();
    this.headerService.setActiveNavElement('.notes');
    console.log('ngOnInit');
    this.dataStorageService.fetchNews()
      .subscribe(
        (response) => {
          console.log(response);
          const newsArray: NewsModel[] = response.json();
          this.newsService.setNews(newsArray ? newsArray : []);
        }
      );
  }

}
