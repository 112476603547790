import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../header/header.service';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {

  public showVdo = false;

  toggleVdo() {
    this.showVdo = !this.showVdo;
  }

  constructor(
    private headerService: HeaderService
  ) { }

  ngOnInit() {
    console.log('here');
    this.headerService.removePreviouslyActiveNavElement();
    this.headerService.setActiveNavElement('.home');
  }

}
