import { Component, OnInit } from '@angular/core';
import { OpportunitiesService } from '../opportunities.service';
import { OpportunitiesModel } from '../opportunities.model';
import { DataStorageService } from 'src/app/shared/data-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-opportunities-list',
  templateUrl: './opportunities-list.component.html',
  styleUrls: ['./opportunities-list.component.css']
})
export class OpportunitiesListComponent implements OnInit {

  oppArray: OpportunitiesModel[];
  isAuthenticated: boolean;

  constructor(
    private opportunitiesService: OpportunitiesService,
    private datastorageService: DataStorageService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService) { }

  ngOnInit() {
    this.isAuthenticated = this.authService.isAuthenticated();
    console.log('opportunities-list component - ngOnInit');
    this.datastorageService.fetchOpportunities()
      .subscribe(
        (res) => {
          console.log(res);
          const oppArray: OpportunitiesModel[] = res.json();
          this.opportunitiesChanged();
          this.opportunitiesService.setOpportunities(oppArray);
        }
      );
  }

  opportunitiesChanged() {
    console.log('opportunities-list - inside opportunitiesChanged()');
    this.opportunitiesService.opportunitiesChanged.subscribe(
      (oppArray: OpportunitiesModel[]) => {
        this.oppArray = oppArray;
      }
    );
  }

  onEditOpportunity(i: number) {
    console.log('edit opportunity');
    this.router.navigate([`/people/opportunities/${i}/edit`], { relativeTo: this.route });
  }

  // changeStyle($event) {
  //   if ($event.type === 'mouseenter') {
  //     console.log('entering');
  //   } else {
  //     console.log('leaving');
  //   }
  // }
}
