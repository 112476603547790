import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { NewsModel } from '../news.model';
import { NewsService } from '../news.service';
import { AuthService } from '../../auth/auth.service';
import { DataStorageService } from 'src/app/shared/data-storage.service';

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.css']
})
export class NewsDetailComponent implements OnInit {
  newsPost: NewsModel;
  id: number;
  unsavedChangesExist: boolean;

  constructor(private newsService: NewsService,
    private route: ActivatedRoute,
    private router: Router,
    public authService: AuthService,
    private dataStorageService: DataStorageService,) { }

  ngOnInit() {
    this.route.params
      .subscribe(
        (params: Params) => {
          this.id = +params['id'];
          this.newsPost = this.newsService.getNewsPost(this.id);
        });
  }

  onEditNews() {
    this.router.navigate(['../', this.id, 'edit'], { relativeTo: this.route });
  }

  onDeleteNews() {
    console.log(this.id);
    this.newsService.deleteNews(this.id);
    this.unsavedChangesExist = true;
  }

  onConfirmDeleteNews() {
    this.dataStorageService.storeNews().subscribe(res => {
      this.onCancel();
    });
  }

  onCancel() {
    this.dataStorageService.fetchNews()
      .subscribe(
        (response) => {
          console.log(response);
          const newsArray: NewsModel[] = response.json();
          this.newsService.setNews(newsArray ? newsArray : []);
          this.unsavedChangesExist = false;
          this.router.navigate(['/news']);
        }
      );
  }
}
