import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { PeopleModel } from './people.model';

@Injectable({
  providedIn: 'root'
})
export class PeopleService {
  // Note Subject object doesn't allow duplicates
  peopleChanged = new Subject<PeopleModel[]>();

  private peopleArray: PeopleModel[] = [];

  setPeople(peopleArray: PeopleModel[]) {
    this.peopleArray = peopleArray;
    this.peopleChanged.next(peopleArray);
    console.log(this.peopleArray);
  }

  getPeople() {
    return this.peopleArray.slice();
  }

  addPeople(person: PeopleModel) {
    this.peopleArray.push(person);
  }

  deletePeople(i: number) {
    this.peopleArray.splice(i, 1);
    this.peopleChanged.next(this.peopleArray);
  }

  updatePeople(id: number, person: PeopleModel) {
    this.peopleArray[id] = person;
  }
}
